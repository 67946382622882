import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'
import { useEffect, useState, Suspense } from 'react'
import * as THREE from 'three'
import { Loader } from "@react-three/drei";

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
   
    
        <Experience />
      
     
    
     </>
)

