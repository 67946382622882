/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/asansor-novida-8.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["brushedmetal.004"]}
        position={[71.8173, 63.9412, 62.2446]}
        scale={[0.157, 63.9399, 12.5007]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["brushedmetal.004"]}
        position={[71.6422, 64.7043, 98.5104]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[23.7344, 23.6332, 63.211]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["brushedmetal.004"]}
        position={[71.7857, 64.1438, 26.0494]}
        scale={[0.1387, 63.8321, 23.7472]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials["brushedmetal.004"]}
        position={[1.5826, 65.9916, 62.2446]}
        scale={[0.157, 63.9399, 12.5007]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["brushedmetal.004"]}
        position={[1.7371, 64.7043, 98.5104]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[23.7344, 23.6332, 63.211]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={materials["brushedmetal.004"]}
        position={[1.8823, 64.1438, 26.0494]}
        scale={[0.1387, 63.8321, 23.7472]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={materials["brushedmetal.003"]}
        position={[36.5361, 125.7833, 62.4283]}
        scale={[35.2034, 34.3173, 60.0606]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010100987_-1_1010502515_-1_1010502893_-1"]
            .geometry
        }
        material={materials["brushedmetal.004"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010300878_-1_1010300882_-1"].geometry
        }
        material={materials["brushedmetal.004"]}
        position={[0, 0, 0.1508]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010300878_-1_1010300882_-2"].geometry
        }
        material={materials["brushedmetal.004"]}
        position={[36.6869, 12.795, 2.289]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010300887_-1_1010300889_-1"].geometry
        }
        material={materials["brushedmetal.004"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010300887_-2_1010300889_-1"].geometry
        }
        material={materials["brushedmetal.004"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010401007_-1_1010400371_-1_1010400573_-1"]
            .geometry
        }
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010401007_-1_1010400371_-1_1010400575_-1"]
            .geometry
        }
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010401007_-1_1010400371_-2_1010400573_-1"]
            .geometry
        }
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010401007_-1_1010400371_-2_1010400575_-1"]
            .geometry
        }
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010401007_-1_1010600194_-1"].geometry
        }
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["1000000835_STL_-_1010600194_-1"].geometry}
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["1000000835_STL_-_1010600194_-2"].geometry}
        material={materials.kolmetal}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bloom10.geometry}
   
        position={[44.8515, 115.9039, 62.2347]}
        rotation={[Math.PI / 2, 0, 0]}>
          <meshBasicMaterial color={[50,50,50]} toneMapped={false} />

     </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bloom11.geometry}
        position={[44.8515, 115.9039, 62.2347]}
        rotation={[Math.PI / 2, 0, 0]}>
           <meshBasicMaterial color={[20,20,20]} toneMapped={false} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1010100987_-1_1010502515_-1_1010502893_-1001"]
            .geometry
        }
        material={materials["brushedmetal.004"]}
        position={[0, 0, -59.1141]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003.geometry}
        material={materials["brushedmetal.001"]}
        position={[73.1663, 2.6473, 122.5469]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[2.8859, 1, 60.9139]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane005.geometry}
        material={materials.Material}
        position={[1.6537, 64.0319, 49.6489]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[61.9889, 59.5885, 0.2121]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane006.geometry}
        material={materials.Material}
        position={[24.1368, 67.3921, 2.2614]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={[61.8137, 59.5885, 0.1051]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials.mermer}
        position={[36.6858, 3.6432, 62.3021]}
        rotation={[0, 0, -0.0053]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane008.geometry}
        material={materials["Material.003"]}
        position={[71.08, 86.8805, 62.241]}
        rotation={[-2.3562, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane007.geometry}
        material={materials.Material}
        position={[1.7331, 64.0319, 74.8215]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[61.9889, 59.5885, 0.2121]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009.geometry}
        material={materials.Material}
        position={[49.2373, 67.3921, 2.2614]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={[61.8137, 59.5885, 0.1051]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane010.geometry}
        material={materials.Material}
        position={[36.6782, 20.0245, 2.2614]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[12.5993, 59.5885, 0.1051]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane011.geometry}
        material={materials.Material}
        position={[36.6782, 112.1616, 2.2614]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[12.5993, 59.5885, 0.1051]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={materials.Material}
        position={[71.6916, 64.0319, 74.8215]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[61.9889, 59.5885, 0.2121]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane012.geometry}
        material={materials.Material}
        position={[71.6652, 64.0319, 49.6489]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[61.9889, 59.5885, 0.2121]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101026_-1001"].geometry
        }
        material={materials["brushedmetal.002"]}
        position={[71.264, 67.7538, 62.3017]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.05}>
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["1000000835_STL_-_1040101025_-1_1040101026_-1005"].geometry
          }
          material={materials["yazilar.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["1000000835_STL_-_1040101025_-1_1040101026_-1005_1"].geometry
          }
          material={materials["Material.005"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101059_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101058_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101057_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101056_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes[
            "1000000835_STL_-_1040101025_-1_1040101055_-1_1040101049_-1_002"
          ].geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101054_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101053_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101052_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101051_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["1000000835_STL_-_1040101025_-1_1040101050_-1_1040101049_-1_"]
            .geometry
        }
        material={materials["brushedmetal.002"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
    </group>
  );
}

useGLTF.preload("/asansor-novida-8.glb");
