import { OrbitControls, useHelper, Environment, Stage,Center,ContactShadows, Reflector, MeshReflectorMaterial, useProgress, Html } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import { Suspense, useRef, useState } from 'react'
import Placeholder from './Placeholder.js'
import Hamburger from './Hamburger.js'
import {  Bloom,  EffectComposer } from '@react-three/postprocessing'
import * as THREE from 'three'
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
export default function Experience()
{
    const directionalLight = useRef()
    const directionalLight2 = useRef()
    const ref = useRef()

    const [lerping, setLerping] = useState(false)
    const [to, setTo] = useState(new THREE.Vector3(0, 2, 0))
    const [target, setTarget] = useState(new THREE.Vector3(0, 2, 0))
    
    function Animate({ controls, lerping, to, target}) {
      useFrame(state => {
        
  
        if(lerping){
    state.camera.position.lerp(to, .04)  
      controls.current.target.lerp(target, .04)
      
        }
    
      })
    }

    function gotoAnnotation(e) {

  
      setTo(new THREE.Vector3(-0.3, 0.08, 0.0) )
      setTarget(new THREE.Vector3(0, 0.08, 0.0));
  
     
    
      setLerping(true)
    }

    function gotoAnnotation2(e) {

  
      setTo(new THREE.Vector3(-1.3, 0, 2.7) )
      setTarget(new THREE.Vector3(0, 0, 0));
  
     
    
      setLerping(true)

      setTimeout(gotoAnnotation3, 1500) // 5000 milisaniye = 5 saniye
    }

    
    function gotoAnnotation3(e) {

  
      setTo(new THREE.Vector3(-1.32, 0, 2.75) )
      setTarget(new THREE.Vector3(-1.32, 0, 2.75));
  
     
    
      setLerping(false)
    }

    function Loader() {
      const { active, progress, errors, item, loaded, total } = useProgress()
      var num= Number(progress)
    
      return <Html center>
        <div id="preloader-container"><img id="gift" src="preloader-main-black4.gif" alt="Be patient..." /></div>
        <div id="loading-container">
          <p> {num.toFixed()} % Yükleniyor </p>
        </div>
      </Html>
    }
    

 
   
    return <>
     <Canvas
  style={{ background: "white" }}
  shadows
  camera={ {
      fov: 45,
      near: 0.01,
      far: 1000,
      position: [ -1.3, 0, 2.7 ]
  } }

  
>
<Suspense fallback={<Loader />}>
  <EffectComposer>
           
          
           <Bloom
               mipmapBlur
               intensity={ 0.2 }
               luminanceThreshold={ 1 }
           />
           
           
       </EffectComposer>
        {/* <Perf position="top-left" /> */}

        <OrbitControls ref={ref} makeDefault  enableZoom={true} minPolarAngle={Math.PI / 4} maxPolarAngle={Math.PI /1.7} />

        <Animate controls={ref} lerping={lerping} to={to} target={target} />


        <Environment
        background={false}
   
    blur={0.5}
    files="/neutral.hdr"
    resolution={512}
   
    
   
/>
<color args={ [ '#ffffff' ] } attach="background" />
        <directionalLight ref={ directionalLight } castShadow position={ [ 3, 4, 3 ] } intensity={ 0 } shadow-normalBias={ 0.04 } />
       
        <ambientLight intensity={ 0.1 } /> 
      
       
       
        <pointLight color="white" intensity={1} position={[0, 0.7, -0.45]}  rotation={[-1.57,0,0]} />
       
        <pointLight color="white" intensity={1} position={[0, 0.7, +0.35]}  rotation={[-1.57,0,0]}  />
 
        <ContactShadows position={[0, -0.8, 0]} opacity={0.7} scale={10} blur={0.5} far={0.8} />
        <Center center>
            <Hamburger   scale={0.01} />
            </Center>

            <mesh rotation={[0, Math.PI * 0.0001, 0]} position={[-0.003,0.02,-0.599]}>
  <planeGeometry args={[0.255, 0.93]} />
  <MeshReflectorMaterial 
    color="white"
    blur={[1024, 1024]} // Blur ground reflections (width, height), 0 skips blur
    mixBlur={0.75} // How much blur mixes with surface roughness
    mixStrength={0.6} // Strength of the reflections
    resolution={2048} // Off-buffer resolution, lower=faster, higher=better quality
    mirror={1} // Mirror environment, 0 = texture colors, 1 = pick up env colors
    minDepthThreshold={0.25}
    maxDepthThreshold={1}
    depthScale={50}
    metalness={0.5} 
    roughness={1}  
  />
</mesh>

</Suspense>
</Canvas>

<div id="overlay2"
      className="overlay2">
        <button id="button2"   onClick={(e) => (e.stopPropagation(), gotoAnnotation())}>
          <b>ışıklar</b>
        </button>
      </div> 

      <div id="overlay2"
      className="overlay2">
        <button id="button3"   onClick={(e) => (e.stopPropagation(), gotoAnnotation2())}>
          <b>geri</b>
        </button>
      </div>

      <div id="overlay2"
      className="overlay2">
        <button id="button4"   onClick={(e) => (e.stopPropagation(), gotoAnnotation3())}>
          <b>geri2</b>
        </button>
      </div>

    </>
}